import * as Sentry from "@sentry/nuxt"

const target = useRuntimeConfig().public.app.target
const $config = useRuntimeConfig().public.sentry

const tracePropagationTargets = target === 'production' ? [/^https:\/\/([^/]+\.)?augwa\.com/] : ["localhost", /^https?:\/\/([^/]+\.)?augwa\.dev/]

Sentry.init({
    dsn: $config.dsn,
    environment: target,
    enabled: $config.enabled,
    integrations: [Sentry.replayIntegration()],
    tracesSampleRate: $config.tracesSampleRate,
    tracePropagationTargets,
    replaysSessionSampleRate: $config.replaysSessionSampleRate,
    replaysOnErrorSampleRate: $config.replaysOnErrorSampleRate,
})
